import * as React from "react";
import Layout from "../components/Layout";

const AboutPage = () => {
  return (
    <Layout>
      <div className="card full-width">
        <div className="card-content">
          <h1 className="title" id="title">
            {" "}
            Chronik{" "}
          </h1>

          <div className="content">
            <p>
              Am 6. Oktober 1962 wurde die Schützengilde von Alexander Atzert
              (1), Karl-Heinz Kern (2), Willi (3) und Fritz-Günther Tölle, Emil
              (4) und Werner Möller (9), Ferdinand Füller (5), Josef Bleß,
              Roland Heinrich (7) und Josef Leibold (8) gegründet. Was diese
              Männer an diesem Tag ersonnen, wurde in der Schützenklause „Zum
              kühlen Grund“ begonnen. Man zeigte den Bürgern des Königreiches in
              der Turnhalle was Schießsport heißt und baute den Verein nun auf.
              Bald hatten sich schon einige Männer eingeschrieben. Alexander
              Atzert war durch ihre Wahl, den Verein zu führen, ihr Ideal. Ihm
              zur Seite standen Kern, Leibold, Schriftführer Bleß und Heinrich
              als Kassierer
            </p>
            <p>
              Der erste Schützenkönig des jungen Vereins wurde das
              Gründungsmitglied Werner Möller. Er ist hier links auf dem Foto zu
              sehen. Rechts auf dem Bild ist der Ehrenpräsident Otto Atzert aus
              Washington anlässlich seines Besuches in der alten Heimat zu
              sehen. Er spendete unserem Verein einen angenehmen Geldbetrag.
            </p>
            <p>
              &nbsp;In der Versammlung 1963 wurde über Pläne eines Schießstandes
              verhandelt. Durch großzügige Unterstützung der Gemeindeverwaltung
              wurde durch einstimmigen Gemeindebeschluss ein Gelände in der
              leide dem Verein zur Verfügung gestellt. Bis zur Fertigstellung
              des Schießstandes wurden die Schießstunden im Vereinslokal „Zum
              kühlen Grund“ abgehalten.&nbsp;
            </p>
            <p>
              Nachdem in der Weihnachtswoche 1962, an einem bitterkalten Tag mit
              den Erdarbeiten begonnen worden war, konnte die Schießbahn im Mai
              1963 fertig ausgeschoben werden. In viertägiger Arbeit wurde der
              erste Bauabschnitt bewältigt. Da der eine vorhandene
              Luftgewehrstand in dem Vereinslokal bei weitem nicht mehr den
              Anforderungen genügte, erweiterten die Schützenbrüder in einer
              Nachtschicht vor Einbruch des Winters die Maueröffnung auf drei
              Stände. Schon kurze Zeit später fand die Einweihung, verbunden mit
              einem gemütlichen Beisammensein statt.
            </p>
            <p>
              An einem Sonntagmorgen im Frühjahr 1964 fielen die ersten Schüsse
              in der neuen Schießbahn. Der Stand wurde ersetzt durch Tisch und
              Stuhl. Der Fels wurde gesprengt um das Fundament für die Halterung
              der Zuganlagen einzulassen. Die Blendefundamente wurden ausgehoben
              und betoniert. Die Schützenbrüder Kurt Karger und Erich Scheich
              beim Einsetzen der U-Eisen für die Zuganlagen. Um einen
              Stromanschluss zu bekommen, verlegte eine Firma im Sommer 540 m
              Erdkabel von der Schlagmühle zum Schießstand. Im Herbst wurden die
              Blenden aufgestellt und das Fundament für das Schützenhaus
              betoniert.
            </p>
            <p>
              Im Jahre 1965 wurden die Arbeiten am Schützenhaus vorangetrieben.
              Eine Baufirma erstellte im Sommer den Rohbau und im August konnte
              das Richtfest gefeiert werden.
            </p>
            <p>
              Eine Gruppenaufnahme von 1967 zeigt die Mitglieder des
              Schützenvereins zu dieser Zeit. Leider sind davon schon viele
              Schützen verstorben. Das Bild kann man in unserem Vereinslokal
              ansehen.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AboutPage;
